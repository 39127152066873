// 最外层盒子样式
%out-box {
  //   width: (1920rem / 100);
  position: relative;
}
// 内层盒子
%inside-box {
  width: (1200rem / 100);
  margin: 0 auto;
}
.regulations-container {
  @extend %out-box;
  padding-bottom: 1.6rem;
  background: url("https://image.bookgo.com.cn/%20webculture/jm/bg/bg_body.jpg");
  &-bread {
    @extend %inside-box;
    padding-top: 0.78rem;

    font-size: 0.14rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #273143;
    margin-bottom: 0.28rem;
  }
  &-pic {
    width: 6.6rem;
    height: 1.25rem;
    position: absolute;
    top: 0;
    right: 3.6rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
